@import '../utils/var';
@import '../utils/mx';
@import '../utils/mq';

.ctl {
	display: flex;
	flex-direction: column;
	@include min768 {
		flex-direction: row;
	}
}

.ctl__aside {
	position: relative;
	width: 100%;
	display: none;
	padding: 80px 0 55px;

	@include min768 {
		padding: 0;
		min-width: 223px;
		display: block;
	}
}

.ctl__content { }

.cat__list {
	display: flex;
	flex-wrap: wrap;
}

.cat__item {
	width: 50%;
	padding-bottom: 20px;

	@include min768 {
		padding-left: 20px;
	}

	@include min960 {
		width: 33.33%;
	}

	@include min1200 {
		width: 25%;
	}

	.card__item {
		width: 100%;

		.card__item-footer {
			.ctrl-basket {
				margin-right: 10px;
				width: 118px;
			}
		}
	}
}

.ctl__count {
	display: inline-block;
	position: relative;
	bottom: 3px;
	padding-left: 17px;
	font-size: 11px;
	line-height: 17px;
	color: $gray2;
	white-space: nowrap;

	@include min768 {
		padding-left: 20px;
		bottom: 4px;
	}
}

.ctlg-nav {
	margin-bottom: 40px;
	display: none;

	@include min768 {
		display: block;
	}
}

.ctlg-nav__title {
	margin-bottom: 15px;

	a {
		display: flex;
		align-items: center;
		font-size: 17px;
		line-height: 30px;
		color: $gray;
		text-decoration: none;

		svg {
			margin-right: 10px;
		}
	}
}

.ctlg-nav__list {
	padding-left: 12px;
	font-size: 14px;
	line-height: 16px;
}

.ctlg-nav__item {

	a {
		display: inline-block;
		padding: 7px 10px 8px;
		text-decoration: none;
		border-radius: 7px;
		color: $gray2;

		&:hover {
			opacity: .9;
		}
	}
	&.ctlg-nav__item_current a {
		background: #FDF4ED;
		display: block;
	}
}

.cat__filter {
	position: relative;
	font-weight: 400;
	padding: 20px;
	border-bottom: 1px solid $graylight;

	@include min768 {
		padding: 0;
		border-bottom: 0;
		margin-bottom: 40px;
	}

	&.m-reset {
		display: none;

		@include min768 {
			display: block;
		}
	}

	&:last-child {
		border-bottom: 0;
	}
}

.cat__filter-btn-reset {
	background-color: $red;
	width: 100%;
	border-radius: 7px;
	transition: background-color .25s ease;

	&:hover {
		background-color: rgba($red, .75);
	}
}

.cat__filter-ctrl-list-reset {
	cursor: pointer;
	padding: 0 4px;
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;


	svg {
		width: 100%;
		height: auto;
	}

	@include min768 {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
}

.cat__filter-title {
	position: relative;
	margin-bottom: 20px;

	.cat__filter-crtl-close {
		display: none;
	}

	.m-ictrl & {
		margin-bottom: 0;
	}
}

.cat__filter-title-grid {
	position: relative;
	display: block;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	padding-right: 120px;

	@include min375 {
		font-size: 17px;
	}

	@include min768 {
		font-size: 14px;
		padding-right: 0;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	.tooltip {
		display: block;
	}

	span {
		display: inline-block;

		&:first-child {
			margin-right: 10px;
		}

		&.ctrl_ios {
			height: 20px;
		}

		&.cat__filter-crtl-back {
			display: none;
		}
	}


	.cat__filter.m-ictrl & {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;
		padding-right: 0;

		@include min768 {
			margin-bottom: 20px;
		}
	}

	.cat__filter.m-radio & {
		display: flex;
		margin-bottom: 0;
		padding-right: 0;

		@include min768 {
			margin-bottom: 20px;
		}
	}
}

.cat__filter-search {
	margin-bottom: 25px;

	@include min768 {
		max-width: 191px;
	}
}

.cat__filter-list {

	@include min768 {
		.cat__filter[data-isloaded="1"] & {
			height: 210px;
			overflow-y: scroll;
			scrollbar-color: $gray3 $graylight;
			scrollbar-width: thin;
			
			&::-webkit-scrollbar {
				width: 4px;
				background-color: $gray3;
			}
			
			&::-webkit-scrollbar-track {
				border-radius: 7px;
				background-color: $graylight;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 7px;
				background-color: $gray3;
			}
		}
	}	
}

.cat__filter-item {
	display: flex;
	align-items: center;
	padding-bottom: 23px;

	&:last-child {
		margin-bottom: 0;
	}

	.cat_count {
		padding-left: 5px;
		color: $gray3;
	}

	@include min768 {
		padding-bottom: 10px;
	}

	.cat__filter[data-view="grid"] & {
		display: inline-block;
		margin-right: 8px;
		padding-bottom: 10px;

		&:nth-child(n+8) {
			display: none;
		}

		.cat_count {
			display: none;
		}

		.checkbox-btn {
			overflow: hidden;
			white-space: nowrap;

			input[type="checkbox"] {
				display: none;

				&:checked + span {
					background-color: $pink;
					color: #fff;
				}
			}

			span {
				background-color: $graylight;
				height: 35px;
				line-height: 35px;
				padding: 0 10px;
				border-radius: 7px;
			}
		}
	}
}

.cat__filter-ctrl-load {
	cursor: pointer;
	font-weight: 400;
	font-size: 11px;
	line-height: 17px;
	position: absolute;
    top: 21px;
    right: 20px;
    display: flex;
	align-items: center;
	white-space: nowrap;

	.icon_load {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		margin-right: 2px;

		@include min768 {
			margin-right: 8px;
		}

		svg path {
			fill: $textColor;
		}
	}

	@include min768 {
		position: relative;
		top: inherit;
		right: inherit;

		.cat__filter[data-isloaded="1"] & {
			display: none;
		}
	}
}

.cat__filter-rangelist {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cat__filter-range {
	width: 48%;
	@include min768 {
		width: 105px;
	}
}

.cat__filter-rangectrl {
	padding: 27px 10px 0;

	@include min768 {
		// display: none;
	}
}

.cat__top,
.cat__bottom {
	@include min768 {
		padding-left: 20px;
	}
}

.cat__bottom-row {
	padding: 25px 0;
}

.cat__top-row {
	margin-bottom: 30px;

	&.m-flex {
		display: flex;
		align-items: center;
	}
}

.cat__top-cell {
	margin-left: 10px;

	&:first-child {
		min-width: 0px;
		margin-left: 0;

		@include min768 {
			min-width: 180px;
		}
	}
}

.cat__filter-btn {
	position: relative;
	cursor: pointer;
	padding: 0 10px;
	height: 35px;
	line-height: 35px;
	display: flex;
	align-items: center;
	border-radius: 7px;

	@include min768 {
		display: none;
	}

	svg {
		margin-right: 10px;
	}

	.cat__filter_notif {
		width: 24px;
		height: 24px;
		background: $red;
		border: 2px solid #fff;
		border-radius: 50%;
		color: #fff;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		position: absolute;
		top: -10px;
		right: -10px;
	}
}

// mobile
.ctlg-filtermob__top {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 60px;
	z-index: 99;
	display: none;
}
.ctlg-filtermob__top-grid {
	padding: 20px;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 17px;
	line-height: 25px;
	position: relative;
	width: 100%;
	height: 60px;
}

.cat__filter-crtl-reset {
	color: $red;
	font-size: 11px;
	line-height: 13px;
	padding-left: 10px;
	cursor: pointer;
}

.cat__filter-crtl-close {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY( -50% );
}

.cat__filter-crtl-back {
	display: none;
}

.ctlg-filtermob__bottom {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 55px;
	z-index: 99;
	display: none;

	@include min768 {
		display: none;
	}
	.btn {
		width: 100%;
		height: 55px;
		line-height: 55px;
		border-radius: 0;
	}
}

body.m-cat-filter {
	.top,
	.header,
	.footer,
	.crumbs,
	.page__title,
	.ctl__content {
		display: none;
	}
	.ctl__aside,
	.ctlg-filtermob__top,
	.ctlg-filtermob__bottom {
		display: block;
	}
	.page {
		padding: 0;
		margin: 0;
	}
}

body.cat_filter_show {
	.cat__filter {
		display: none;
	}
	.cat__filter.filter_view_mobile {
		display: block;
		border-bottom: 0;

		.cat__filter-ctrl-load {
			display: none;
		}

		.cat__filter-crtl-back {
			display: inline-block;
		}

		.cat__filter-crtl-close {
			display: inline-block;
		}

		.tooltip {
			display: none;
		}

		.cat__filter-title {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 60px;
			z-index: 100;
		}
		.cat__filter-title-grid {
			padding: 20px;
			background: #fff;
			box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
			display: flex;
			align-items: center;
			font-weight: 500;
			font-size: 17px;
			line-height: 25px;
			position: relative;
			width: 100%;
			height: 60px;
		}
	}
}

